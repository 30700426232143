<script>
  import View from "./View.svelte";
  import AutoHide from "./AutoHide.svelte";
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
  }

  .space-before {
    flex: 1 0 20vh;
  }

  .space-after {
    flex: 2 0 10vh;
  }

  span {
    font-weight: 400;
    opacity: 0.6;
    font-size: 2em;
  }
</style>

<div class="container">
  <div class="space-before" />
  <AutoHide>
    <span>Hey, I'm</span>
  </AutoHide>
  <AutoHide>
    <h1>Jan Kaifer</h1>
  </AutoHide>
  <AutoHide>
    <p>Welcome to my website</p>
  </AutoHide>
  <div class="space-after" />
</div>
