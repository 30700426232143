<script>
  import View from "./View.svelte";
  import AutoHide from "./AutoHide.svelte";
  import Project from "./Project.svelte";
</script>

<style>
  .projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5vw;
  }

  .space-after {
    height: 10vh;
  }
</style>

<AutoHide>
  <h3>Projects I took part in</h3>
</AutoHide>
<div class="projects">
  <Project
    name="Pochopim.to"
    src="/assets/pochopimto.png"
    href="https://pochopim.to" />
  <Project name="Retino.io" src="/assets/retino.png" href="https://retino.io" />
  <Project
    name="Netrex"
    src="/assets/netrex.png"
    href="https://netrex.cz" />
</div>
<div class="space-after" />
